import { SET_AUTH, CLEAR_AUTH } from './actionTypes';

var setAuth = function setAuth(auth) {
  return {
    type: SET_AUTH,
    payload: auth
  };
};

var clearAuth = function clearAuth() {
  return {
    type: CLEAR_AUTH
  };
};

export { clearAuth, setAuth };